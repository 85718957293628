.react-select__control {
  border-radius: 0 !important;
  height: 32px !important;
  min-height: 32px !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
}

.react-select__indicator {
  padding: 4px !important;
}

.react-select__menu {
  width: auto !important;
}

.react-select__option {
  white-space: nowrap !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 300 !important;
}

.is-disabled .react-select__control{
  border: 1px solid #cccccc !important;
}
.has-error .react-select__control{
  border: 1px solid #d0021b !important;
}