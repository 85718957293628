@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: #f4f4f4;
}


/* For Firefox */

input[type='number'] {
    -moz-appearance: textfield;
}


/* Webkit browsers like Safari and Chrome */

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}